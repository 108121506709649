<template>
  <div>
    <a-row :gutter="24" type="flex">
      <a-col :span="24" class="mb-24">
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0, }">
          <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12">
                <h5 class="font-semibold m-0">关注管理</h5>
              </a-col>
              <a-col class="function" :span="24" :md="12"
                style="display: flex; align-items: center; justify-content: flex-end">
                <a-button @click="add" type="primary" size="small">
                  新增
                </a-button>
                <a-button @click="editClassify" size="small">配置板块</a-button>
                <a-button @click="syncFollow" size="small">同步</a-button>
                <a-button @click="exportExcel" size="small">导出</a-button>
                <a-button @click="batchDel" size="small" type="danger">批量删除</a-button>
                <!-- <a-button size="small" @click="test">测试爬虫</a-button> -->
                <!-- <a-button size="small" @click="chattest">测试chatgpt</a-button> -->
              </a-col>
            </a-row>
          </template>
          <a-table :columns="table1Columns" :data-source="table1Data" :pagination="false" :rowKey="record => record.id"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
            <template slot="name" slot-scope="name">
              <a slot="name" :href="'https://twitter.com/' + name" target="_blank">{{ name }}</a>
            </template>

            <template slot="state" slot-scope="state">
              <a-tag color="green" v-if="state">
                已关注
              </a-tag>
              <a-tag color="red" v-else>
                已取消
              </a-tag>
            </template>

            <template slot="action" slot-scope="row">
              <a-button type="link" size="small" :data-id="row.key" @click="editFollow(row)">
                <span v-if="row.state">取消</span>
                <span v-else>关注</span>
              </a-button>
              <a-button type="link" size="small" :data-id="row.key" @click="del(row)">
                <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="fill-danger" fill-rule="evenodd" clip-rule="evenodd"
                    d="M9 2C8.62123 2 8.27497 2.214 8.10557 2.55279L7.38197 4H4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6L4 16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16V6C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H12.618L11.8944 2.55279C11.725 2.214 11.3788 2 11 2H9ZM7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8V14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14V8ZM12 7C11.4477 7 11 7.44772 11 8V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V8C13 7.44772 12.5523 7 12 7Z"
                    fill="#111827" />
                </svg>
                <span class="text-danger">删除</span>
              </a-button>
            </template>

          </a-table>
        </a-card>
      </a-col>
    </a-row>
    <a-modal :visible="visible.add.show" title="新增关注" @ok="handleSubmit" @cancel="visible.add.show = false">
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" ref="form">
        <a-form-item label="用户名称">
          <a-input placeholder="可添加多个用户，需逗号分隔，如hebi555,Ice_Frog666666"
            v-decorator="['name', { rules: [{ required: true, message: '请输入tw用户名称' }] }]" />
        </a-form-item>
        <a-form-item label="板块">
          <a-cascader :options="classifyList" placeholder="请选择关联板块"
            :field-names="{ label: 'name', value: 'id', children: 'children' }"
            v-decorator="['classifyId', { rules: [{ required: true, message: '请选择关联板块' }] }]" />
        </a-form-item>
      </a-form>
    </a-modal>

    <a-modal :visible="visible.edit.show" title="配置板块" @ok="handleEditSubmit" @cancel="visible.edit.show = false">
      <a-form :form="editForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" ref="form">
        <a-form-item label="板块">
          <a-cascader :options="classifyList" placeholder="请选择关联板块"
            :field-names="{ label: 'name', value: 'id', children: 'children' }"
            v-decorator="['classifyId', { rules: [{ required: true, message: '请选择关联板块' }] }]" />
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- <a-select v-decorator="['classifyId', { rules: [{ required: true, message: '请选择关注分类' }] }]">
            <a-select-option v-for="(item, index) in classifyList" :key="index" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select> -->
  </div>
</template>

<script>

const table1Columns = [
  {
    title: 'tw用户',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '昵称',
    dataIndex: 'nickname',
    scopedSlots: { customRender: 'nickname' },
  },
  // {
  //   title: '简介',
  //   dataIndex: 'description',
  //   scopedSlots: { customRender: 'description' },
  //   ellipsis: true,
  // },
  {
    title: '一级板块',
    dataIndex: 'firstClassifyName',
    key: "firstClassifyName",
    filters: [],
    width:120,
    onFilter: (value, record) => {
      debugger
      return record.firstClassifyId == value
    },
  },
  {
    title: '二级级板块',
    dataIndex: 'secondClassifyName',
    key: "secondClassifyName",
    filters: [],
    width:180,
    onFilter: (value, record) => {
      debugger
      return record.secondClassifyId == value
    },
  },
  {
    title: '状态',
    key: 'state',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' },
    width: 80,
    filters: [{
      text: '已关注',
      value: 1,
    },
    {
      text: '已取消',
      value: 0,
    }],
    onFilter: (value, record) => {
      return record.state == value
    },

  },
  {
    scopedSlots: { customRender: 'action' },
    key: 'action',
    width: 190,
  }
];
const key = 'add';
import { syncFollow,batchDelFollow, exportFollow, getClassifyList, getFollowList, addFollow, delFollow, updateFollow, testFollow, chattestFollow, editFollowClassify } from "@/api/service.js";
export default ({
  components: {
  },
  data() {
    return {
      table1Data: [],
      table1Columns: table1Columns,
      visible: {
        add: {
          show: false
        },
        edit: {
          show: false
        }
      },
      form: this.$form.createForm(this),
      editForm: this.$form.createForm(this),
      classifyList: [],
      classifyObj: {},
      selectedRowKeys: []
    }
  },
  mounted() {
    this.getClassifyList();
  },
  methods: {
    syncFollow(){
      syncFollow();
    },
    batchDel() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning({ content: '请选择至少一条需要删除的数据', duration: 2 });
        return
      }
      let that = this;
      this.$confirm({
        title: '你确认要删除此关注吗?',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          batchDelFollow({ ids: that.selectedRowKeys }).then(res => {
            that.$message.success({ content: '删除成功！', duration: 2 });
            that.getClassifyList();
          })
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    exportExcel() {

      exportFollow().then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'follow-data.xlsx'); // 文件名
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link); // 清理DOM
        window.URL.revokeObjectURL(url);  // 释放URL 对象
      })
        .catch((error) => {
          console.error('导出数据时出错:', error);
        });
    },
    handleEditSubmit() {
      this.$message.loading({ content: '正在保存', key });
      this.editForm.validateFields((err, values) => {
        if (!err) {
          values.classifyId = values.classifyId[values.classifyId.length - 1];
          values.id = this.selectedRowKeys;
          editFollowClassify(values).then(res => {
            this.$message.success({ content: '配置成功', key, duration: 2 });
            this.visible.edit.show = false;
            this.getList();
          }).catch(err => {
            this.$message.error({ content: '配置失败！' + err, key, duration: 2 });
          })
        }
      });
    },
    editClassify() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning({ content: '请选择至少一条需要修改的数据', duration: 2 });
        return
      }
      this.visible.edit.show = true;
      this.$nextTick(() => {
        if (this.editForm) {
          this.editForm.resetFields();
        }
      });
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    chattest() {
      chattestFollow();
    },
    test() {
      testFollow({
        href: 'https://twitter.com/Ice_Frog666666'
      });
    },
    editFollow(row) {
      let state = row.state == 0 ? 1 : 0;
      let msg = state == 0 ? '取消关注' : '关注';
      updateFollow({ id: row.id, state }).then(res => {
        this.$message.success({ content: msg + '成功', duration: 2 });
        this.getList();
      })
    },
    getClassifyList() {
      let firstClassifyFilters = [], secondClassifyFilters = [];
      this.$message.loading({ content: '加载中...', key });
      getClassifyList().then(res => {
        this.classifyList = res.data.data;
        this.classifyList.forEach(item => {
          if (!item.parentId) {
            firstClassifyFilters.push({
              text: item.name,
              value: item.id
            })
          }
          if (!item.children) {
            item.disabled = true;
          } else {
            item.children.forEach(item2 => {
              this.classifyObj[item2.id] = item2.name;
              secondClassifyFilters.push({
                text: item2.name,
                value: item2.id
              })
            })
          }
          this.classifyObj[item.id] = item.name;
        });
        this.table1Columns = this.table1Columns.map(column => {
          if (column.dataIndex === 'firstClassifyName') {
            return {
              ...column,
              filters: firstClassifyFilters
            };
          } else if (column.dataIndex === 'secondClassifyName') {
            return {
              ...column,
              filters: secondClassifyFilters
            };
          }
          return column;
        });

        this.getList(true);
      })
    },
    handleSubmit() {
      this.$message.loading({ content: '正在新增', key });
      this.form.validateFields((err, values) => {
        if (!err) {
          values.classifyId = values.classifyId.toString();
          addFollow(values).then(res => {
            this.$message.success({ content: '新增成功', key, duration: 2 });
            this.visible.add.show = false;
            this.getList();
          }).catch(err => {
            this.$message.error({ content: '新增失败！' + err, key, duration: 2 });
          })
        }
      });
    },
    getList(flag) {
      getFollowList().then(res => {
        res.data.data.forEach(item => {
          let classifyId = item.classifyId.split(',');
          item.firstClassifyId = classifyId[0];
          item.secondClassifyId = classifyId[1];
          item.firstClassifyName = this.classifyObj[classifyId[0]];
          item.secondClassifyName = this.classifyObj[classifyId[1]];
          // item.classifyName = this.classifyObj[item.classifyId];
        })
        this.table1Data = res.data.data;
        if (flag) {
          this.$message.destroy(key);
        }
      })
    },
    add() {
      this.visible.add.show = true;
      this.$nextTick(() => {
        if (this.form) {
          this.form.resetFields();
        }
      });
    },
    del(row) {
      let that = this;
      this.$confirm({
        title: '你确认要删除此用户?',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          delFollow({ id: row.id }).then(res => {
            that.$message.success({ content: '删除成功', duration: 2 });
            that.getList();
          })
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  }
})

</script>

<style lang="scss"></style>